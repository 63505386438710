<template>
  <div class="Receipt">
    <div class="container">
      <h3>{{ entity.CompanyName }}</h3>
      <div class="cell"><span>单号:</span>{{ entity.Id }}</div>
      <div class="cell">
        <span>日期:</span>{{ entity.CreateTime?entity.CreateTime.substring(0, 19):'' }}
      </div>
      <div class="cell"><span>客户:</span>{{ entity.cCusName }}</div>
      <div
        class="tableRow"
        style="border-bottom: 1px dashed #ccc; margin-top: 10px; line-height: 2"
      >
        <span class="tableIndex"></span>
        <span class="tableCol left">品名/编码</span>
        <span class="tableCol">数量</span>
        <!-- <span class="tableCol">单位</span> -->
        <span class="tableCol">单价</span>
        <span class="tableCol">小计</span>
      </div>
      <div style="padding: 10px 0">
        <div v-for="(item, index) in data" :key="index">
          <div class="tableRow">
            <span class="tableIndex">{{ index + 1 }}</span>
            <span class="tableCol left">{{ item.InventName }}</span>
            <span class="tableCol">{{ item.Quantity }}</span>
            <span class="tableCol">{{ item.Price?item.Price.toFixed(2):'' }}</span>
            <span class="tableCol">{{ item.Money?item.Money.toFixed(2):'' }}</span>
          </div>
          <div class="tableRow">
            <span class="tableIndex"></span>
            <span class="tableCol left">{{ item.cInvCode }}</span>
            <span class="tableCol">{{ item.GroupName }}</span>
            <span class="tableCol col2" v-if="item.cInvStd">规格: {{ item.cInvStd }}</span>
          </div>
        </div>
      </div>
      <div
        class="tableRow"
        style="border-top: 1px dashed #ccc; line-height: 2; margin-bottom: 10px"
      >
        <span class="tableIndex"></span>
        <span class="tableCol left">合计</span>
        <span class="tableCol">{{ entity.TotalQuantity }}</span>
        <span class="tableCol"></span>
        <span class="tableCol">{{ entity.TotalSum?entity.TotalSum.toFixed(2):'' }}</span>
      </div>
      <div class="cell" style="display: flex">
        <span style="width: 30%;display: block" v-if="entity.Type == 0">现收:{{entity.ReceiveSum.toFixed(2)}}</span>
        <span style="width: 30%;display: block" v-if="entity.Type == 1">挂账:{{ entity.Type == 0 ? entity.ReceiveSum.toFixed(2):entity.PendingSum.toFixed(2)}}</span>
        <span style="width: 30%;display: block" v-if="entity.Type == 2">赊货:{{entity.ReceiveSum.toFixed(2)}}</span>
      </div>
      <div style="text-align: center; padding-top: 30px;  margin-bottom:60px">
        <span style="display: block">扫码查看电子小票</span>
        <vue-qr
          class="box"
          :text="codeValue"
          :logoScale="0.2"
          :size="200"
        ></vue-qr>
      </div>
    </div>
    <div style="width: 95%; " class="fixBtn" >
      <a v-if="href" :href="href" style="color: #fff;">
        <el-button  type="primary" style="width:100%">
          去签收
        </el-button>
      </a>
    </div>

  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      data: [],
      codeValue: "",
      entity: {},
      href: "",
    };
  },
  created() {

  },
  mounted() {
    // 短信签收
    console.log('挂载', this.entity)
    console.log('创建',  this.$route.query.Id, this.$route.query.billId)
    this.codeValue = location.href;
    this.getTheData(this.$route.query.Id);
    if (this.$route.query.billId) this.getFormData(this.$route.query.billId);
    this.getShareInfo();
  },
  methods: {
    getFormData(id) {
      this.$http.post("/BO/BillList/GetBillDetail", { id }).then((res) => {
        if (res.Success) {
          this.$http
            .post("/BO/BillList/GetBillLink", {
              id: res.Data.QrCode,
            })
            .then((result) => {
              if (result.Success) {
                this.href = result.Data;
              }
            });
        }
      });
    },
    getTheData(Id) {
      this.$http.post("/BO/BIlling/GetBllingDetail?Id=" + Id).then((res) => {
        if (res.Success) {
          this.data = res.Data.detalist;
          this.entity = res.Data.maindata;
          if(this.$route.query.billId==1){
            this.$route.query.billId=res.Data.maindata.BillListId
            console.log('打印entity、改变',  this.$route.query.billId)
            this.getFormData(this.$route.query.billId)
          }
        } else this.$message.error(res.Msg);
      });
    },
    // 获取微信认证
    async getShareInfo() {
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?url=" + url
        // "/BO/BillList/GetWxSign?url=https://psq.yoojet.com/"
      );
      let arr;
      if (ticketData.Data.length !== 0) {
        arr = ticketData.Data.split(",");
      }

      const config = {
        appId: "wxde354ac424a66adc",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: "电子送货单",
          desc: this.entity.CompanyName + "的电子小票",
          link: location.href,
          imgUrl: "https://psq.yoojet.com/wx.png",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.fixBtn {
  position: fixed;
  left: 10px;
  right: 10px;
  bottom:0;
  padding-bottom: 20px;
  background-color: #fff;
}
.Receipt {
  //   display: flex;
  //   justify-content: center;
  position: relative;
  .container {
    margin: 0 auto;
    color: #000;
    min-width: 216px;
    max-width: 100%;
    padding: 10px;
    h3 {
      text-align: center;
    }
    .cell {
      display: flex;
      line-height: 2;
      span {
        width: 60px;
      }
    }
    .tableRow {
      font-size: 12px;
      display: flex;
      align-items: center;
      span {
        overflow: hidden;
      }
      .tableCol {
        text-align: right;
        width: 75px;
        overflow: hidden;
      }
      .left {
        text-align: left;
        flex: 1;
      }
      .tableIndex {
        width: 25px;
      }
      .col2 {
        width: 150px;
      }
    }
  }
}
</style>